import React, {Component} from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import CatoButton from '../components/cato-button';
import StickyBannerCTA from '../components/sticky-banner-cta';
import CatoImg from '../components/cato-image';
import {videoSectionMapper, mapCoolInsights, mapGartner, canUseWebP} from '../common/utils'
import {GTag, IS_MOBILE, IS_DESKTOP, IS_TABLET, IS_SMALL_DESKTOP, LANG, HOMEPAGE_TRANSLATIONS_DATA} from '../common/consts';
import CatoTicker from '../components/cato-ticker';
import StructuredData from '../components/structured-data';
import {pickBy, identity} from 'lodash';
import CatoLink from '../components/cato-link';

import BannerVideo from '../assets/video/home/Header_Normal Size.mp4';

import WanTransformationChallenge from "../components/wan-transformation-challange";

import WhiteArrow from '../assets/images/homepage/White_arrow.svg';
import BlackArrow from '../assets/images/homepage/Black_arrow.svg';
import Video from '../components/video';
import Map from "../components/map";
import VS from "../components/VS";
import VideoLightbox from '../components/video-lightbox';

import '../assets/styles/pages/_home.scss';
import '../assets/styles/responsive/pages/_home-small-desktop.scss';
import '../assets/styles/responsive/pages/_home-tablet.scss';
import '../assets/styles/responsive/pages/_home-mobile.scss';

class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pauseBanner: false,
            isMobile: false,
            isDesktop: false,
            isTablet: false,
            isSmallDesktop: false,
            webP: true,
            loadTicker: false
        }
    }

    componentDidMount() {
        let newState = {...this.state};

        if(IS_MOBILE) {
            newState = {...newState, isMobile: true}
        } else if(IS_DESKTOP){
            newState = {...newState, isDesktop: true}
        } else if(IS_TABLET) {
            newState = {...newState, isTablet: true}
        } else if (IS_SMALL_DESKTOP) {
            newState = {...newState, isSmallDesktop: true}
        }


        const webP = canUseWebP();

        if(!webP) {
            newState = {...newState, webP: false}
        }

        this.setState({...newState, loadTicker: true})
    }

    mapSection = (homepageMapSection) => {
        const counters = homepageMapSection.hpMapNumbersWithIcons ? homepageMapSection.hpMapNumbersWithIcons.map(item => {
            const endIcon = item.animate ? item.greenNumberText.slice(-1) : null;
            const finishString = item.animate ? item.greenNumberText.split(endIcon)[0] : item.greenNumberText;
            const isFloat = finishString.indexOf('.') > -1;

            const final = isFloat ? parseFloat(finishString) : item.animate ? parseInt(finishString, 10) : finishString;

            const start = isFloat ? 0.001 : null;

            const step = isFloat ? 0.001 : item.animate ? final > 100 ? 5 : 1 : null;

            const interval = item.animate ? isFloat ? 1 : final > 100 ? 50 : 100 : null;

            return ({
                count: item.animate,
                icon: item.icon && item.icon.localFile ? item.icon.localFile : null,
                finish: final,
                float: isFloat,
                start,
                step,
                interval,
                endIcon,
                bottomText: item.bottomText

            })}) : null;

        return <Map id="global-backbone" headline={homepageMapSection.hpMapHeadline} image={homepageMapSection.hpMapImage} mapNumbersWithIcons={homepageMapSection.hpMapNumbersWithIcons} counters={counters} />;

    };


    handleCTAEvent = (label) =>
    {
        GTag({category: 'Home', action: 'Main CTA Click', label: `${label.label}, ${label.path}`, nonInteraction: false});
    };


    genStructuredOrgData = (structuredOrganizationData) => {
        const org = structuredOrganizationData.organizationData;
        return (
            <StructuredData
                type="Organization"
                data={{
                    url:'https://www.catonetworks.com',
                    legalName: org.structuredLegalName ? org.structuredLegalName : null,
                    foundingDate: org.structuredFoundingDate ? org.structuredFoundingDate : null,
                    founders: org.structuredFounders ? org.structuredFounders.map(i =>
                        (pickBy({
                            '@type': 'Person',
                            "name": i.founderName
                        }, identity))) : null,
                    address: org.structuredAddress ? org.structuredAddress.map(i => (pickBy({
                        '@type': 'PostalAddress',
                        streetAddress: i.streetAddress || null,
                        addressLocality: i.city || null,
                        addressRegion: i.region || null,
                        postalCode: i.postalCode || null,
                        addressCountry: i.country || null,

                    }, identity))) : null,
                    contactPoint: org.structuredContact ? org.structuredContact.map(i => (pickBy({
                        '@type': 'ContactPoint',
                        contactType: i.contactType || null,
                        telephone: i.phoneNumber || null,
                        email: i.email || null,
                        contactOption: i.contactPption ? i.contactOption : null,
                        areaServed: i.areaServed && i.areaServed.length ? i.areaServed.map(j => j.area) : null,
                        availableLanguage: i.languages && i.languages.length ? i.languages.map(j => j.language) : null
                    }, identity))) : null,
                    sameAs: org.structuredSocialAccounts && org.structuredSocialAccounts.length ? org.structuredSocialAccounts.map(i => i.socialAccount) : null
                }}
            />
        )

    };

    genTicker = (customerLogos) => {
        const {isSmallDesktop, isDesktop} = this.state;
        const dimension = (dim) => {
            let calcString = `${dim}px / 2`;

            if(this.state.isMobile) {
                calcString = `${dim}px * 0.33`;
            } else if (this.state.isSmallDesktop) {
                calcString = `${dim}px * 0.33`;
            }

            return `calc(${calcString})`;
        };

        const calcStyle = (img) => ({
            width: dimension(img.childImageSharp.original.width + 2),
            height: dimension(img.childImageSharp.original.height + 2),
            maxHeight: '100%'
        })

        const height = isSmallDesktop ? 100 : isDesktop ? 120 : 75;
        return (
            <div className="customer-logos">
                <CatoTicker
                    height={height}
                    items={customerLogos.map((item, i) => {
                        const img = item.customerLogo.localFile ? <CatoImg img={item.customerLogo.localFile} style={calcStyle(item.customerLogo.localFile)}/> : null;

                        const logo = item.link
                            ?
                            <CatoLink path={item.link} style={{height: '100%'}}>
                                {img}
                            </CatoLink>
                            :
                            img

                        return (
                            <div className="logo-wrapper" key={`partner-logo-${i}`}>
                                {/*<div style={{width: '100%', height: '100%'}}>*/}
                                {logo}
                                {/*</div>*/}
                            </div>
                        )}
                    )}
                />
            </div>
        )
    }

    render() {

        const {data} = this.props;
        const {wpPage} = data;
        const {structuredOrganizationData, pageSeoTitle, hpVideoBanner, homepageWANSixPack, homepageVersusSection, homepageMapSection, videoSection} = wpPage;
        const {isDesktop, isTablet} = this.state;
        // const banner = acf ? this.bannerSection(acf) : null;
        const vs = homepageVersusSection ? <VS {...{
            heading: homepageVersusSection.versusSectionHeadline || "",
            subHeader: homepageVersusSection.versusSectionLightText || "",
            title_left: homepageVersusSection.versusLeftSideTitleText || "",
            title_right: homepageVersusSection.versusRightSideTitleText || "",
            subtitle_left: homepageVersusSection.versusLeftSideBoldText || "",
            subtitle_right: homepageVersusSection.versusRightSideBoldText || "",
            img_left: homepageVersusSection.versusLeftSideImage.localFile ? homepageVersusSection.versusLeftSideImage.localFile : null,
            img_right: homepageVersusSection.versusRightSideImage.localFile ? homepageVersusSection.versusRightSideImage.localFile : null,
            text_left: homepageVersusSection.versusLeftSideParagraph || "",
            text_right: homepageVersusSection.versusRightSideParagraph || ""
        }} /> : null;
        const map = homepageMapSection ? this.mapSection(homepageMapSection) : null;

        const youtube = videoSection && videoSection.videos && (isDesktop || isTablet) ? videoSectionMapper(videoSection.videoSectionHeadline, videoSection.videos) : null;

        const coolInsights = mapCoolInsights(wpPage);

        const stickyBanner = !wpPage.stickyBanner ? false : {
            label: wpPage.stickyBanner.stickyBannerLabel,
            link: wpPage.stickyBanner.stickyBannerLink,
            goldenLabel: wpPage.stickyBanner.goldenButtonText,
            goldenLink: wpPage.stickyBanner.goldenButtonLink,
        };

        const gartner = mapGartner(wpPage);

        // const centerPadding = !this.state.isMobile ? "20px" : "5px";

        const ticker = this.state.loadTicker ? this.genTicker(hpVideoBanner.customerLogos) : <div className={'ticker-placeholder'} style={{height: '100px'}} />;

        const structuredOrgData = structuredOrganizationData && structuredOrganizationData.organizationData ? this.genStructuredOrgData(structuredOrganizationData) : null;

        const structuredWebSiteData = <StructuredData type="WebSite" data={{
            name: 'Cato Networks',
            url: 'https://www.catonetworks.com',
            sameAs: structuredOrganizationData && structuredOrganizationData.organizationData.structuredSocialAccounts
            && structuredOrganizationData.organizationData.structuredSocialAccounts.length
                ?
                structuredOrganizationData.organizationData.structuredSocialAccounts.map(i => i.socialAccount)
                : null
        }}/>

        return (
            <Layout className="home"
                    yoastData={wpPage.seo || null}
                    lang={LANG.DE}
                    whiteMenu={false}
                    seoTitle={pageSeoTitle.pageSeoTitle}
                    title={wpPage.title}
                    gartner={gartner}
                    seoPath={this.props.location.pathname}
                    translations={HOMEPAGE_TRANSLATIONS_DATA}
                    {...{youtube}}
                    {...{coolInsights}}
            >
                <section className="banner-section video">
                    <div className="video-wrapper">
                        {
                            isTablet || isDesktop ?
                                this.state.loadTicker ?
                                    <Video muted={true} autoPlay={true} controls={false} loop={false} poster={data.bannerVideoPoster.childImageSharp.fluid.srcWebp} sources={[{src: BannerVideo, type: 'video/mp4'}]}/>
                                    :
                                    <CatoImg style={{width: '100%'}} img={data.bannerVideoPoster}/>
                                :
                                <CatoImg img={data.mobileBanner} />
                        }
                    </div>
                    <div className="content-wrap">
                        {hpVideoBanner.bannerHeadline && <h1>{hpVideoBanner.bannerHeadline}</h1>}
                        {hpVideoBanner.bannerButtons.length > 0 && <div className="buttons">
                            {hpVideoBanner.bannerButtons[0] && <CatoButton
                                path={hpVideoBanner.bannerButtons[0].link}
                                className="br-4"
                                label={hpVideoBanner.bannerButtons[0].label}
                                onClick={this.handleCTAEvent}
                                icon={BlackArrow}/>}
                            {hpVideoBanner.bannerButtons[1] && <CatoButton
                                path={hpVideoBanner.bannerButtons[1].link}
                                className="br-4"
                                label={hpVideoBanner.bannerButtons[1].label}
                                onClick={this.handleCTAEvent}
                                icon={WhiteArrow}/>}
                        </div>
                        }
                    </div>
                </section>
                <section className="customers">
                    <div className="customer-logos">
                        {ticker}
                    </div>
                    <div className="customer-videos">
                        {hpVideoBanner.videosHeadline && <h3>{hpVideoBanner.videosHeadline}</h3>}
                        { hpVideoBanner.videosSlides &&
                        <VideoLightbox
                            type={'column'}
                            playButtonOnHover={true}
                            includeSpacer={false}
                            arrows={!this.state.isMobile}
                            includeShadow={false}
                            slidesToScroll={1}
                            variableWidth={true}
                            slidesToShow={this.state.isMobile ? 1 : 4}
                            centerMode={false}
                            items={hpVideoBanner.videosSlides.map((item) => ({
                                vidId: item.code,
                                title: item.title,
                                vidImg: item.image ? item.image : null
                            }))}
                        />
                        }
                    </div>
                    <div className="quote-wrapper">
                        {hpVideoBanner.quote && <div className="quote" dangerouslySetInnerHTML={{__html: hpVideoBanner.quote}}/>}
                        {hpVideoBanner.quoteSource && <div className="citation">{hpVideoBanner.quoteSource}</div>}
                        {hpVideoBanner.quoteLogo && <CatoImg img={hpVideoBanner.quoteLogo} />}
                    </div>
                </section>
                {structuredWebSiteData}
                {structuredOrgData}
                {
                    <WanTransformationChallenge title={homepageWANSixPack.wanTransformationSectionTitle}
                                                items={homepageWANSixPack.wanTransformationSectionItems} />
                }
                {vs}
                {map}
                {
                    (stickyBanner) &&
                    <StickyBannerCTA {...{stickyBanner}}/>
                }
            </Layout>
        )
    }
}

export default Home;


export const query = graphql`
    query {
    wpPage(language: {code: {eq: DE}}, templateFileName: {eq: "front-page.php"}) {
        ...YoastData
        id
        title
        pageSeoTitle: pageSeoTitle {
            pageSeoTitle
        }
        hpVideoBanner {
            bannerHeadline: hp2021BannerHeadline
            bannerButtons: hp2021BannerButtons {
                label
                link
            }
            videosHeadline: hp2021CustomerVideosHeadline
            videosSlides: hp2021CustomerVideosVideos {
                code
                title
                image {
                    altText
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 394, quality: 90) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                        publicURL
                    }
                }
            }
            quote: hp2021Quote
            quoteSource: hp2021QuoteSource
            quoteLogo: hp2021QuoteLogo {
                altText
                localFile {
                    publicURL
                }
            }
            customerLogos: hp2021CustomerLogos {
                customerLogo: logo {
                    altText
                    localFile {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                            original {
                                width
                                height
                            }
                        }
                        publicURL
                    }
                    sourceUrl
                }
                link
            }
        }
        homepageWANSixPack {
            wanTransformationSectionTitle
            wanTransformationSectionItems {
                buttonText
                link
                icon {
                    altText
                    localFile {
                        publicURL
                    }
                }
            }
        }
        homepageVersusSection {
            versusSectionHeadline
            versusSectionLightText
            versusLeftSideImage {
                altText
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 352) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                    publicURL
                }
            }
            versusLeftSideTitleText
            versusLeftSideBoldText
            versusLeftSideParagraph
            versusRightSideImage {
                altText
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 445) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64 
                        } 
                    }
                    publicURL
                }
            }
            versusRightSideTitleText
            versusRightSideBoldText
            versusRightSideParagraph
        }
        homepageMapSection {
            hpMapHeadline
            hpMapImage {
                altText
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1600){
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                    publicURL
                }
            }
            hpMapNumbersWithIcons {
                icon {
                    localFile {
                        childImageSharp {
                            original {
                                width
                                height
                            }
                        }
                        publicURL
                    }
                }
                greenNumberText
                animate
                bottomText
            }
        }
        tryUsStrip {
            tryUsHeadingText
            tryUsButtonText
        }
        structuredOrganizationData {
            organizationData {
                structuredLogo {
                    sourceUrl
                }
                structuredAddress {
                    streetAddress
                    city
                    region
                    country
                    postalCode
                }
                structuredSocialAccounts {
                    socialAccount
                }
                structuredLegalName
                structuredFoundingDate
                structuredFounders {
                founderName
                }
                structuredContact {
                    phoneNumber
                    contactType
                    contactOption
                    languages {
                      language
                    }
                    areasServed {
                      area
                    }
                }
            }
        }
        ...StickyBanner
        ...VideoSection
        ...ResourcesTrio
        ...GartnerStrip
    }
    bannerVideoPoster: file(relativePath: { eq: "homepage/load-image.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
            }
        }
    }
    mobileBanner: file(relativePath: { eq: "homepage/mobile bg.png" }) {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
            }
        }
    }
}`
