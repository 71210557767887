import React, {Component} from 'react';
import './style.scss';

class StickyBanner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fixed: false,
            initOffset: 0
        };

        this.banner = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.isFixed)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.isFixed)
    }


    isFixed = () => {
        const banner = this.banner.current;
        const offset = window.pageYOffset + document.documentElement.clientHeight - banner.clientHeight;

        if(this.state.initOffset > 0) {
            if (offset >= this.state.initOffset) {
                this.setState({...this.state, fixed: true})
            }

            else if (offset <= this.state.initOffset) {
                this.setState({...this.state, fixed: false});
            }
        } else if(this.state.initOffset === 0) {
            this.setState({...this.state, initOffset: this.banner.current.offsetTop})
        }

    };

    render() {
        return (
            <div className={`abs-page-pos-50-percent ${this.props.className || ''}`}>
                <div ref={this.banner} className={`sticky-banner-wrapper${this.state.fixed ? ' fixed': ''}`}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default StickyBanner;