import React, {useEffect, useRef, useState} from "react";
import useScrollPosition from "use-scroll-position";
import CatoImg from "../cato-image";
import IconCounter from "../icon-counter";
import {IS_MOBILE} from '../../common/consts';

const Map = ({image, mapNumbersWithIcons, counters, headline = '', id}) => {

    const [transformX, setTransformX] = useState(0);

    const myRef = useRef(null);
    // const scrollPosition = useScrollPosition();
    // This is pure magic, do not touch
    // useEffect(() => {
    //     if (typeof window === 'undefined' || !IS_MOBILE) return null;
    //     const fromBottom = myRef.current.getBoundingClientRect().top + window.pageYOffset - window.innerHeight;
    //     if(fromBottom - scrollPosition < 0) {
    //         const to100percent = window.innerHeight;
    //         const percent = Math.abs(fromBottom - scrollPosition) / to100percent;
    //         setTransformX(percent*30);
    //     }
    //     return () => {}
    // }, [myRef.current && myRef.current.getBoundingClientRect()]);

    return (
        <section className="map-section" id={id}>
            <h2 dangerouslySetInnerHTML={{__html: headline}}/>
            <div className="container">
                <div className={'map-wrapper'} style={{transform: `translateX(${-transformX}%)`}} ref={myRef}>
                    <CatoImg img={image} alt="Map" loading={'lazy'}/>
                </div>
                {mapNumbersWithIcons &&
                <ul className="counters hide-bullets-style">
                    {counters.map((item, i) => <li key={`icon-counter-${i}`}><IconCounter {...item} /></li>)}
                </ul>
                }
            </div>
        </section>
    );
};

export default Map;