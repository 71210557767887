import React from "react";
import CatoImg from '../cato-image';

const VS = ({heading, subHeader, title_left, title_right, subtitle_left, subtitle_right, img_left, img_right, text_left, text_right}) => (
    <section className={'versus-section'}>
        {heading && <h2 dangerouslySetInnerHTML={{__html: heading}}/>}
        {subHeader && <h3 dangerouslySetInnerHTML={{__html: subHeader}} />}
        <div className={'vs-wrapper'}>
            <div className={'item left'}>
                {title_left && <h3>{title_left}</h3>}
                {subtitle_left && <h4>{subtitle_left}</h4>}
                {img_left && <CatoImg img={img_left} loading={'lazy'} width={285} height={460}/>}
                {text_left && <div className={'text'} dangerouslySetInnerHTML={{__html: text_left}}/>}
            </div>
            <div className={'vs'}>VS.</div>
            <div className={'item right'}>
                {title_right && <h3>{title_right}</h3>}
                {subtitle_right && <h4>{subtitle_right}</h4>}
                {img_right && <CatoImg img={img_right} loading={'lazy'} width={400} height={400}/>}
                {text_right && <div className={'text'} dangerouslySetInnerHTML={{__html: text_right}}/>}
            </div>
        </div>
    </section>
);

export default VS;