import React from 'react';
import StickyBanner from '../sticky-banner';
import CatoButton from '../cato-button';
import "./style.scss";

const StickyBannerCTA = ({stickyBanner}) => {
    return(
        <StickyBanner className="sticky-banner-cta">
            <CatoButton label={stickyBanner.label} path={stickyBanner.link || null} className={'oval br-4 mid darker-shadow'} />
            <CatoButton label={stickyBanner.goldenLabel} path={stickyBanner.goldenLink|| null} style={{backgroundImage: 'linear-gradient(to bottom, rgba(253, 202, 11, 0.9), rgba(253, 202, 11, 0.5))', backgroundColor: '#222c33'}} className={'oval br-4 mid darker-shadow'} />
        </StickyBanner>
    )
}

export default StickyBannerCTA;